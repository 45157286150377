import React from 'react'

const CodeAndSoul = ({ solidColor = null, darkMode = false }) => {
    const colors = {
        code: '#2ea973',
        and: '#2ea973',
        soul: '#151515',
    }

    if (darkMode) {
        colors.soul = '#fff'
    }

    if (solidColor) {
        colors.code = solidColor
        colors.and = solidColor
        colors.soul = solidColor
    }

    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="100%"
            height="100%"
            viewBox="0 0 112 80"
        >
            <g
                id="Grupo_2522"
                data-name="Grupo 2522"
                transform="translate(-175.141 -510.791)"
            >
                <path
                    id="Caminho_4008"
                    data-name="Caminho 4008"
                    d="M287.141,514.442h-.018l0-.026a3.714,3.714,0,0,0-3.8-3.625H178.937a3.713,3.713,0,0,0-3.8,3.625v.026h0v72.7h0v.026a3.714,3.714,0,0,0,3.8,3.626l.027,0v0H283.329a3.715,3.715,0,0,0,3.8-3.626l0-.026h.018Z"
                />
                <g id="Grupo_2521" data-name="Grupo 2521">
                    <g id="Grupo_2519" data-name="Grupo 2519">
                        <path
                            id="Caminho_4009"
                            data-name="Caminho 4009"
                            d="M199.988,547.485H184.033a2.527,2.527,0,0,1-2.52-2.52V516.413a2.527,2.527,0,0,1,2.52-2.519h15.955a1.684,1.684,0,0,1,1.68,1.679v5.879a1.685,1.685,0,0,1-1.68,1.679h-12.6a2.527,2.527,0,0,0-2.52,2.519v15.956a2.527,2.527,0,0,0,2.52,2.519h12.6a1.68,1.68,0,0,1,0,3.36Z"
                            fill="#fff"
                        />
                        <path
                            id="Caminho_4010"
                            data-name="Caminho 4010"
                            d="M222.368,547.485H207.294a2.527,2.527,0,0,1-2.52-2.52V516.5a2.491,2.491,0,0,1,2.52-2.477h15.074a2.455,2.455,0,0,1,2.477,2.477v28.468A2.492,2.492,0,0,1,222.368,547.485Zm-.84-27.629a2.527,2.527,0,0,0-2.52-2.519h-8.355a2.527,2.527,0,0,0-2.52,2.519V535.77a2.527,2.527,0,0,0,2.52,2.519h8.355a2.527,2.527,0,0,0,2.52-2.519Z"
                            fill="#fff"
                        />
                        <path
                            id="Caminho_4011"
                            data-name="Caminho 4011"
                            d="M245.587,547.485H227.951V513.894h17.636a2.526,2.526,0,0,1,2.519,2.519v28.552A2.527,2.527,0,0,1,245.587,547.485Zm-.84-27.713a2.526,2.526,0,0,0-2.519-2.519H231.311v20.994h10.917a2.527,2.527,0,0,0,2.519-2.519Z"
                            fill="#fff"
                        />
                        <path
                            id="Caminho_4012"
                            data-name="Caminho 4012"
                            d="M269.687,547.485H253.732a2.527,2.527,0,0,1-2.52-2.52V516.413a2.527,2.527,0,0,1,2.52-2.519h15.955a1.68,1.68,0,1,1,0,3.359h-6.718a2.526,2.526,0,0,0-2.519,2.519v5.878a2.527,2.527,0,0,0,2.519,2.52h6.718a1.68,1.68,0,1,1,0,3.359h-12.6a2.526,2.526,0,0,0-2.519,2.519v7.558a2.526,2.526,0,0,0,2.519,2.519h12.6a1.68,1.68,0,0,1,0,3.36Z"
                            fill="#fff"
                        />
                    </g>
                    <path
                        id="Caminho_4013"
                        data-name="Caminho 4013"
                        d="M222.368,587.674H207.294a2.527,2.527,0,0,1-2.52-2.52V556.686a2.491,2.491,0,0,1,2.52-2.477h15.074a2.455,2.455,0,0,1,2.477,2.477v28.468A2.492,2.492,0,0,1,222.368,587.674Zm-.84-27.629a2.526,2.526,0,0,0-2.519-2.519h-8.356a2.527,2.527,0,0,0-2.52,2.519v15.914a2.527,2.527,0,0,0,2.52,2.519h8.356a2.527,2.527,0,0,0,2.519-2.519Z"
                        fill="#fff"
                    />
                    <path
                        id="Caminho_4014"
                        data-name="Caminho 4014"
                        d="M269.688,587.674H253.774a2.527,2.527,0,0,1-2.519-2.52V555.888a1.679,1.679,0,0,1,3.359,0v20.029a2.527,2.527,0,0,0,2.519,2.519h12.555a1.685,1.685,0,0,1,1.679,1.68v5.878A1.685,1.685,0,0,1,269.688,587.674Z"
                        fill="#fff"
                    />
                    <path
                        id="Caminho_4015"
                        data-name="Caminho 4015"
                        d="M282.39,578.446c-.205-.042-6.516.03-6.714,0a1.222,1.222,0,0,0-1.3,1.214v6.805a1.215,1.215,0,0,0,1.215,1.215c.11.024,6.539-.018,6.648,0a1.235,1.235,0,0,0,1.371-1.215V579.66A1.215,1.215,0,0,0,282.39,578.446Z"
                        fill="#00b01e"
                    />
                    <path
                        id="Caminho_4016"
                        data-name="Caminho 4016"
                        d="M248.081,555.811a1.678,1.678,0,0,0-1.675-1.6h0a1.648,1.648,0,0,0-1.677,1.637v20.113a2.527,2.527,0,0,1-2.519,2.519h-8.356a2.526,2.526,0,0,1-2.519-2.519V555.846a.843.843,0,0,0-.039-.233,1.678,1.678,0,0,0-3.321.485v29.056a2.527,2.527,0,0,0,2.52,2.52h15.074a2.527,2.527,0,0,0,2.519-2.52V555.846C248.085,555.834,248.082,555.823,248.081,555.811Z"
                        fill="#fff"
                    />
                    <path
                        id="Caminho_4017"
                        data-name="Caminho 4017"
                        d="M199.19,587.674h-16a1.68,1.68,0,0,1,0-3.36h6.76a2.49,2.49,0,0,0,2.519-2.477v-7.6a2.491,2.491,0,0,0-2.519-2.477l-1.323-.042a2.491,2.491,0,0,1-2.519-2.478V556.686a2.49,2.49,0,0,1,2.519-2.477h7.32a1.649,1.649,0,0,1,1.68,1.637,1.685,1.685,0,0,1-1.68,1.68h-4a2.526,2.526,0,0,0-2.519,2.519v5.836a2.527,2.527,0,0,0,2.519,2.52h7.243a2.491,2.491,0,0,1,2.478,2.519v14.234A2.492,2.492,0,0,1,199.19,587.674Z"
                        fill="#fff"
                    />
                    <g id="Grupo_2520" data-name="Grupo 2520">
                        <path
                            id="Caminho_4018"
                            data-name="Caminho 4018"
                            d="M178.826,558.531h5.627a.147.147,0,0,0,.15-.144V554.9a.609.609,0,0,0-.572-.64h-4.783a.61.61,0,0,0-.572.64v3.483A.147.147,0,0,0,178.826,558.531Zm.713-.95v-2.013a.325.325,0,0,1,.272-.354h2.672a.326.326,0,0,1,.273.354v2.013Z"
                            fill="#fff"
                        />
                        <path
                            id="Caminho_4019"
                            data-name="Caminho 4019"
                            d="M184.028,568.936h-.694a.559.559,0,0,0-.576.539v.312a.115.115,0,0,1-.061.1.138.138,0,0,1-.132,0l-.635-.368v-1.521c0-.111.069-.188.131-.188h2.02a.508.508,0,0,0,.52-.428.45.45,0,0,0-.126-.361.527.527,0,0,0-.378-.159l-.023,0a.215.215,0,0,0-.043-.005h-4.783a.468.468,0,0,0-.1.011.548.548,0,0,0-.473.53v1.083a.112.112,0,0,0,0,.027.461.461,0,0,0,.237.376l4.9,2.839a.565.565,0,0,0,.281.074.524.524,0,0,0,.384-.159.456.456,0,0,0,.109-.2.123.123,0,0,0,.014-.06v-1.9A.559.559,0,0,0,184.028,568.936Zm-2.96-.776v.855l-1.447-.838c-.074-.046-.075-.047-.075-.121v-.062c0-.111.069-.188.131-.188H180.8A.322.322,0,0,1,181.068,568.16Z"
                            fill="#fff"
                        />
                        <path
                            id="Caminho_4020"
                            data-name="Caminho 4020"
                            d="M179.684,563.73l4.45-.73a.616.616,0,0,0,.469-.63v-1.493a.6.6,0,0,0-.572-.641h-4.9a.477.477,0,0,0,0,.95h3.434a.2.2,0,0,1,.193.189v.73c0,.077-.05.167-.193.188l-3.367.566a.615.615,0,0,0-.5.631v1.02a.61.61,0,0,0,.571.641h4.9a.477.477,0,0,0,0-.95h-4.481c-.072,0-.131-.084-.131-.189v-.091A.185.185,0,0,1,179.684,563.73Z"
                            fill="#fff"
                        />
                    </g>
                </g>
            </g>
        </svg>
    )
}

export default CodeAndSoul
