import React from 'react'
import PropTypes from 'prop-types'
import TransitionLink from 'gatsby-plugin-transition-link'
import { TimelineLite } from 'gsap'
import { injectIntl } from 'gatsby-plugin-intl'

class FadeLink extends React.Component {
    render() {
        const { intl, link, title, className, children, ...other } = this.props

        return (
            <TransitionLink
                to={`/${intl.locale}${link}`}
                title={title}
                className={className}
                {...other}
            >
                {children}
            </TransitionLink>
        )
    }
}

FadeLink.defaultProps = {
    title: '',
    className: '',
}

FadeLink.propTypes = {
    link: PropTypes.string.isRequired,
    title: PropTypes.string,
    className: PropTypes.string,
}

export default injectIntl(FadeLink)
