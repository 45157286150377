/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from 'react';
import PropTypes from 'prop-types';

import ThemeContext from '../context/theme-context';

import AsideMenu from '../components/asideMenu';

class DefaultLayout extends React.Component {
    render() {
        const { children, path } = this.props;

        return (
            <ThemeContext.Consumer>
                {(theme) => {
                    const { toggleMenu, isMenuOpen, currentLocale } = theme;
                    let actualPath = '';

                    if (currentLocale && path) {
                        actualPath = path.replace(`/${currentLocale}`, '');
                    }

                    return (
                        <>
                            <AsideMenu
                                isOpen={isMenuOpen}
                                toggleMenu={toggleMenu}
                                path={actualPath}
                            />

                            {children}
                        </>
                    );
                }}
            </ThemeContext.Consumer>
        );
    }
}

DefaultLayout.propTypes = {
    children: PropTypes.node.isRequired,
};

DefaultLayout.contextType = ThemeContext;

export default DefaultLayout;
