import React, { useState, useEffect } from 'react';
// import { IntlProvider } from 'react-intl'
import { setCookie, getCookie } from '../components/cookie';
import {
    changeLocale as changeLocaleGlobally,
    IntlProvider,
} from 'gatsby-plugin-intl';
// import { useIntl } from 'react-intl';

const ThemeContext = React.createContext({
    isMobile: false,
    currentLocale: 'en',
    darkMode: false,
    setDarkMode: () => {},
    changeLocale: () => {},
    isMenuOpen: false,
    toggleMenu: () => {},
    closeMenu: () => {},
    headerAnimation: false,
});

const ThemeProvider = (props) => {
    const [isMobile, setIsMobile] = useState(false);
    const [currentLocale, setCurrentLocale] = useState('en');
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [darkMode, setDarkMode] = useState(false);
    const [headerAnimation, setHeaderAnimation] = useState(false);

    const changeLocale = (locale) => {
        if (locale !== currentLocale) {
            setCurrentLocale(locale);
            changeLocaleGlobally(locale);
        }

        return locale;
    };

    useEffect(() => {
        if (darkMode) {
            document.getElementsByTagName('body')[0].classList.add('dark-mode');
        } else {
            document
                .getElementsByTagName('body')[0]
                .classList.remove('dark-mode');
        }
    }, [darkMode]);

    useEffect(() => {
        let handleResize = () => {};

        if (typeof window !== 'undefined') {
            handleResize = () => {
                // eslint-disable-next-line react-hooks/exhaustive-deps
                setIsMobile(window.innerWidth < 768);

                // eslint-disable-next-line react-hooks/exhaustive-deps
                return isMobile;
            };

            // eslint-disable-next-line react-hooks/exhaustive-deps
            setIsMobile(window.innerWidth < 768);

            window.addEventListener('resize', handleResize);
        }

        const date = new Date();
        const timeNow = date.getHours();
        const cookie = getCookie('darkMode');
        const cookieValue = !!(cookie != null && cookie == 'true');

        if (timeNow >= 10) {
            document.getElementsByTagName('body')[0].classList.add('dark-mode');
            setDarkMode(true);
        }

        if (cookie != null && cookie !== '') {
            if (cookieValue) {
                document
                    .getElementsByTagName('body')[0]
                    .classList.add('dark-mode');
            } else {
                document
                    .getElementsByTagName('body')[0]
                    .classList.remove('dark-mode');
            }
            setDarkMode(cookieValue);
        }

        return () => {
            if (typeof window !== 'undefined') {
                window.removeEventListener('resize', handleResize);
            }
        };
    }, []);

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };
    const closeMenu = () => {
        setIsMenuOpen(false);
    };

    return (
        <ThemeContext.Provider
            value={{
                isMobile,
                currentLocale,
                changeLocale: changeLocale,
                isMenuOpen,
                toggleMenu,
                closeMenu,
                darkMode,
                headerAnimation,
                setHeaderAnimation,
                setDarkMode: (value) => {
                    setCookie('darkMode', value);
                    setDarkMode(value);
                },
            }}
        >
            <IntlProvider locale={currentLocale}>{props.children}</IntlProvider>
        </ThemeContext.Provider>
    );
};

export default ThemeContext;
export { ThemeProvider };
