import { Link } from 'gatsby';
import React from 'react';
import { injectIntl } from 'gatsby-plugin-intl';

const Footer = ({ intl, darkMode, footerRef }) => {
    return (
        <footer className="footer" ref={footerRef}>
            <div className="terms-privacy-links">
                <span className="footer-link-code">© Code and Soul 2021</span>
                {/* <a href="https://atuo.agency" className="footer-link-atuo">
                    {intl.formatMessage({
                        id: 'footer.link-code',
                    })}
                </a> */}
            </div>
            <div className="description-social">
                <div className="wrap-title">
                    <h3 className="title">
                        {intl.formatMessage({
                            id: 'footer.title',
                        })}
                    </h3>
                    <a
                        href="mailto:hello@codeandsoul.com"
                        className="link-email"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        hello@codeandsoul.com
                    </a>
                </div>
                <div className="social-links">
                    <a
                        href="https://www.facebook.com/codeandsoul"
                        className="social-link"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        Fb
                    </a>
                    <a
                        href="https://www.instagram.com/codeandsoul/"
                        className="social-link"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        Ig
                    </a>
                    <a
                        href="https://twitter.com/codeandsoul"
                        className="social-link"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        Tw
                    </a>
                    <a
                        href="https://www.linkedin.com/company/code-and-soul/"
                        className="social-link"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        In
                    </a>
                    <a
                        href="https://medium.com/@codeandsoul"
                        className="social-link"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        Me
                    </a>
                </div>
            </div>
        </footer>
    );
};

export default injectIntl(Footer);
