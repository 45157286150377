export const setCookie = ( name, value, time = 4 ) => {

    let cookie = `${name}=${value};`;

    cookie = cookie + 'path=/;';

    document.cookie = cookie;

};

export const getCookie = name => {

    const cookie = document.cookie;
    const regex = new RegExp( `${name}=([^;]*)` );

    if ( cookie.match( regex ) ) {

        const cookieValue = cookie.match( regex )[1];

        return cookieValue;

    } else {

        return false;
    
    }

};
