import React, { Component } from 'react'
import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import { Back, TimelineLite } from 'gsap'
import { injectIntl, changeLocale } from 'gatsby-plugin-intl'

import scrollTo from 'gatsby-plugin-smoothscroll'

import FadeLink from '../components/fade-link'
import CodeAndSoul from '../components/svg/code-and-soul-logo'
import Footer from '../components/footer'

import ThemeContext from '../context/theme-context'

class AsideMenu extends Component {
    constructor(props) {
        super(props)
        this.menuRefs = {
            mainContainer: React.createRef(),
            backgroundCover: React.createRef(),
            afterBackgroundCover: React.createRef(),
            contentContact: React.createRef(),
            contentMenu: React.createRef(),
            socialLinks: React.createRef(),
            footer: React.createRef(),
            line: React.createRef(),
            logo: React.createRef(),
            lang: React.createRef(),
            centerLogo: React.createRef(),
            button: React.createRef(),
        }
    }

    componentDidMount() {
        const {
            mainContainer,
            backgroundCover,
            afterBackgroundCover,
            contentContact,
            contentMenu,
            socialLinks,
            footer,
            line,
            logo,
            lang,
            centerLogo,
            button,
        } = this.menuRefs

        this.timeLineAnimation = new TimelineLite({ paused: true })
            .to(mainContainer.current, 0.5, {
                width: '100vw',
                ease: Back.easeOut.config(0.9),
            })
            .to(afterBackgroundCover.current, 0.5, {
                width: '100vw',
                ease: Back.easeOut.config(0.9),
            })
            .to(backgroundCover.current, 0.5, {
                width: '100vw',
                ease: Back.easeOut.config(0.9),
            })
            .to(
                centerLogo.current,
                0.2,
                {
                    opacity: 1,
                    ease: Back.easeOut.config(0.9),
                },
                '-=0.2'
            )
            .to(
                logo.current,
                0.2,
                {
                    marginLeft: 0,
                    opacity: 1,
                    ease: Back.easeOut.config(0.9),
                },
                '-=0.2'
            )
            .to(
                button.current,
                0.2,
                {
                    opacity: 1,
                    ease: Back.easeOut.config(0.9),
                },
                '-=0.2'
            )
            .to(line.current, 0.5, {
                opacity: 1,
                ease: Back.easeOut.config(0.9),
            })
            .to(contentMenu.current, 0.5, {
                opacity: 1,
                ease: Back.easeOut.config(0.9),
            })
            .to(
                contentContact.current,
                0.5,
                {
                    opacity: 1,
                    ease: Back.easeOut.config(0.9),
                },
                '-=0.5'
            )
            .to(lang.current, 0.2, {
                opacity: 1,
                ease: Back.easeOut.config(0.9),
            })
            .to(
                socialLinks.current,
                0.2,
                {
                    opacity: 1,
                    ease: Back.easeOut.config(0.9),
                },
                '-=0.2'
            )
            .to(footer.current, 0.5, {
                opacity: 1,
                ease: Back.easeOut.config(0.9),
            })
    }

    componentDidUpdate() {
        const { isOpen } = this.props
        const tagHtml = document.getElementsByTagName('html')[0]

        if (isOpen) {
            this.timeLineAnimation.play()
            tagHtml.classList.add('fixed')
        } else {
            this.timeLineAnimation.reverse()
            tagHtml.classList.remove('fixed')
        }
    }

    scrollLink = section => {
        this.props.toggleMenu()
        setTimeout(() => {
            scrollTo(section)
        }, 2500)
    }

    renderButton = (section, intlId) => {
        const { intl, path } = this.props

        let actualPage = path.substr(1).split('/')[1];

        if (intlId === 'menu.pages.contact') {
            if (actualPage === '') {
                return (
                    <FadeLink
                        className="menu-text"
                        link={`/contact`}
                        title={intl.formatMessage({
                            id: 'menu.pages.contact',
                        })}
                    >
                        {intl.formatMessage({
                            id: 'menu.pages.contact',
                        })}
                    </FadeLink>
                )
            } else {
                return (
                    <button
                        className="menu-text"
                        onClick={() => this.props.toggleMenu()}
                    >
                        {intl.formatMessage({
                            id: intlId,
                        })}
                    </button>
                )
            }
        }

        if (actualPage === '') {
            return (
                <button
                    className="menu-text"
                    onClick={() => this.scrollLink(section)}
                >
                    {intl.formatMessage({
                        id: intlId,
                    })}
                </button>
            )
        }

        return (
            <FadeLink
                className="menu-text"
                link={`${section}`}
                title={intl.formatMessage({
                    id: intlId,
                })}
            >
                {intl.formatMessage({
                    id: intlId,
                })}
            </FadeLink>
        )
    }

    render() {
        const {
            mainContainer,
            backgroundCover,
            afterBackgroundCover,
            contentContact,
            contentMenu,
            itensMenu1,
            itensMenu2,
            itensMenu3,
            itensMenu4,
            socialLinks,
            footer,
            line,
            logo,
            lang,
            centerLogo,
            button,
        } = this.menuRefs
        const { intl, isOpen, toggleMenu } = this.props

        // const link = window.location.pathname !== '/' ?
        //         '/' + currentLocale :
        //         window.location.pathname;

        return (
            <ThemeContext.Consumer>
                {theme => {
                    const { changeLocale } = theme
                    return (
                        <aside
                            className={`aside-menu ${isOpen ? 'active' : ''}`}
                            ref={mainContainer}
                        >
                            <div
                                className="cover-afterbackground"
                                ref={afterBackgroundCover}
                            />
                            <div
                                className="cover-background"
                                ref={backgroundCover}
                            />

                            <div className="container-menu">
                                <div ref={logo} className="menu-logo">
                                    <FadeLink link={`/`}>
                                        <CodeAndSoul solidColor="#ffffff" />
                                    </FadeLink>
                                </div>
                                <button
                                    ref={button}
                                    type="button"
                                    className="btn-cross"
                                    onClick={toggleMenu}
                                >
                                    <span className="first-line" />
                                    <span className="middle-line" />
                                    <span className="last-line" />
                                </button>
                                <div className="content-wrapper">
                                    <div
                                        className="aside-company-info content-padding"
                                        ref={contentContact}
                                    >
                                        <div className="address">
                                            <span className="contact-item">
                                                <span className="contact-title">
                                                    São Paulo, BR <br />
                                                </span>
                                                <a
                                                    href="tel:+551131361830"
                                                    className="contact-text"
                                                >
                                                    +55 11 3136-1830
                                                </a>
                                            </span>
                                            <span className="contact-item">
                                                <span className="contact-title">
                                                    {/*intl.formatMessage({
                                                        id: ''
                                                    })*/}
                                                    Want to be part of our team?{' '}
                                                    <br />
                                                </span>
                                                <a
                                                    href="mailto:hello@codeandsoul.com"
                                                    className="contact-text"
                                                >
                                                    hello@codeandsoul.com
                                                </a>
                                            </span>
                                        </div>
                                        <div
                                            className="logo-wrapper"
                                            ref={centerLogo}
                                        >
                                            <FadeLink link={`/`}>
                                                <CodeAndSoul solidColor="#ffffff" />
                                            </FadeLink>
                                        </div>
                                    </div>

                                    <span className="hline" ref={line} />

                                    <nav
                                        className="aside-menu-nav content-padding"
                                        ref={contentMenu}
                                    >
                                        <ul>
                                            <li
                                                className="menu-item"
                                                ref={itensMenu1}
                                            >
                                                {this.renderButton(
                                                    '#workSection',
                                                    'menu.pages.work'
                                                )}
                                            </li>
                                            <li
                                                className="menu-item"
                                                ref={itensMenu2}
                                            >
                                                {this.renderButton(
                                                    '#whatWeDoSection',
                                                    'menu.pages.about'
                                                )}
                                            </li>
                                            <li
                                                className="menu-item"
                                                ref={itensMenu3}
                                            >
                                                {this.renderButton(
                                                    '',
                                                    'menu.pages.contact'
                                                )}
                                            </li>
                                            <li
                                                className="menu-item"
                                                ref={itensMenu4}
                                            >
                                                {this.renderButton(
                                                    '#insightsSection',
                                                    'menu.pages.insights'
                                                )}
                                            </li>
                                        </ul>
                                        <div className="terms">
                                            <FadeLink link={`/terms`}>
                                                Terms of Use
                                            </FadeLink>
                                            <FadeLink link={`/privacy`}>
                                                Privacy Notice
                                            </FadeLink>
                                        </div>
                                    </nav>
                                    <div
                                        className="social-links"
                                        ref={socialLinks}
                                    >
                                        <a
                                            href="https://www.instagram.com/codeandsoul/"
                                            className="social-link"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            Ig
                                        </a>
                                        <a
                                            href="https://twitter.com/codeandsoul"
                                            className="social-link"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            Tw
                                        </a>
                                        <a
                                            href="https://www.linkedin.com/company/code-and-soul/"
                                            className="social-link"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            In
                                        </a>
                                        <a
                                            href="https://medium.com/@codeandsoul"
                                            className="social-link"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            Me
                                        </a>
                                    </div>
                                    {/* <span className="lang-btn" ref={lang}>
                                        <button
                                            onClick={() => changeLocale('en')}
                                        >
                                            EN
                                        </button>{' '}
                                        |{' '}
                                        <button
                                            onClick={() =>
                                                changeLocale('pt-br')
                                            }
                                        >
                                            PT
                                        </button>
                                    </span> */}
                                </div>
                                <span className="lang-btn" ref={lang}>
                                    <button onClick={
                                        async() => {
                                            changeLocale('en')
                                        }
                                    }>
                                        EN
                                    </button>{' '}
                                    |{' '}
                                    <button onClick={
                                        async() => {
                                            changeLocale('pt-br')
                                        }
                                    }>
                                        PT
                                    </button>
                                </span>
                            </div>
                            <Footer footerRef={footer} />
                        </aside>
                    )
                }}
            </ThemeContext.Consumer>
        )
    }
}

AsideMenu.defaultProps = {
    isOpen: false,
}

AsideMenu.propTypes = {
    isOpen: PropTypes.bool,
}

export default injectIntl(AsideMenu)
